<template>
  <h2>Responsive Table</h2>
  <button class="addnew-btn" @click="addNew">Add New</button>
  <div class="table-wrapper">
      <table class="fl-table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Categories</th>
            <th>Date</th>
            <th>URL</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="project in projects" :key="project">
            <td>{{project.title}}</td>
            <td>{{project.categories}}</td>
            <td>{{project.date}}</td>
            <td>{{project.url}}</td>
            <td>
                <a href="" :data-id="project._id" @click.prevent="handleEdit">edit</a>&nbsp;
                <a href="" :data-id="project._id" @click.prevent="handleDelete">del</a>
            </td>
          </tr>
        </tbody>
      </table>
  </div>
</template>

<script>
    import {ref} from 'vue'
    import axios from 'axios'
    import {useRouter} from 'vue-router'
    import {getAuth} from '@/composables'
    export default {
        name: 'index',
        setup(){
          const projects = ref(null)
          const headings = ref(null)
          const router = useRouter()
          
          const getProjects = () => {
              axios.get(process.env.VUE_APP_BASE_URL+"/api/projects/all",getAuth()).then((res) => {
                projects.value = res.data
              })
          }
          getProjects()

          const handleDelete = (e) => {
              let id = e.target.dataset.id
              if(confirm('Are you sure?')){
                    axios.delete(process.env.VUE_APP_BASE_URL+"/api/projects/"+id,getAuth()).then((res) => {
                        getProjects()
                        alert(res.data.msg)
                    })
              }
          }

          const handleEdit = (e) => {
              let id = e.target.dataset.id
              console.log('edit',id)
              router.push({name:'project.edit',params:{id}})
          }

          const addNew = () => {
              router.push({name:'project.create'})
          }
          return {projects,headings,handleDelete,handleEdit,addNew}
        }
    }
</script>

<style>
  *{
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
  }
  h2{
      text-align: center;
      font-size: 18px;
      text-transform: uppercase;
      letter-spacing: 1px;
      /* color: white; */
      padding: 30px 0;
  }

  /* Table Styles */

  .table-wrapper{
      margin: 10px 70px 70px;
      box-shadow: 0px 35px 50px rgba( 0, 0, 0, 0.2 );
  }

  .fl-table {
      border-radius: 5px;
      font-size: 12px;
      font-weight: normal;
      border: none;
      border-collapse: collapse;
      width: 100%;
      max-width: 100%;
      white-space: nowrap;
      background-color: white;
  }
  .addnew-btn{
    float: right;
    margin-right: 70px;
    margin-bottom: 10px;
  }
  .fl-table td, .fl-table th {
      text-align: center;
      padding: 8px;
  }

  .fl-table td {
      border-right: 1px solid #f8f8f8;
      font-size: 12px;
  }

  .fl-table thead th {
      color: #ffffff;
      background: #4FC3A1;
  }


  .fl-table thead th:nth-child(odd) {
      color: #ffffff;
      background: #324960;
  }

  .fl-table tr:nth-child(even) {
      background: #F8F8F8;
  }

  /* Responsive */

  @media (max-width: 767px) {
      .fl-table {
          display: block;
          width: 100%;
      }
      .table-wrapper:before{
          content: "Scroll horizontally >";
          display: block;
          text-align: right;
          font-size: 11px;
          color: white;
          padding: 0 0 10px;
      }
      .fl-table thead, .fl-table tbody, .fl-table thead th {
          display: block;
      }
      .fl-table thead th:last-child{
          border-bottom: none;
      }
      .fl-table thead {
          float: left;
      }
      .fl-table tbody {
          width: auto;
          position: relative;
          overflow-x: auto;
      }
      .fl-table td, .fl-table th {
          padding: 20px .625em .625em .625em;
          height: 60px;
          vertical-align: middle;
          box-sizing: border-box;
          overflow-x: hidden;
          overflow-y: auto;
          width: 120px;
          font-size: 13px;
          text-overflow: ellipsis;
      }
      .fl-table thead th {
          text-align: left;
          border-bottom: 1px solid #f7f7f9;
      }
      .fl-table tbody tr {
          display: table-cell;
      }
      .fl-table tbody tr:nth-child(odd) {
          background: none;
      }
      .fl-table tr:nth-child(even) {
          background: transparent;
      }
      .fl-table tr td:nth-child(odd) {
          background: #F8F8F8;
          border-right: 1px solid #E6E4E4;
      }
      .fl-table tr td:nth-child(even) {
          border-right: 1px solid #E6E4E4;
      }
      .fl-table tbody td {
          display: block;
          text-align: center;
      }
  }
</style>