import { useVuelidate } from '@vuelidate/core'

// const validateMethod = new Promise(rules,formData,error,(resolve,reject) => {
//     var validate = useVuelidate(rules,formData,{$error})
//     if (validate.value.$invalid) {
//         reject()
//     } else {
//         resolve(validate)
//     }
// })
var validate;
const validateMethod = (rules,formData,$error) => {
    validate = useVuelidate(rules,formData,{$error})
    return validate
}

// const fail = () => {
//     Toastify({
//         node: cash('#failed-notification-content')
//           .clone()
//           .removeClass('hidden')[0],
//         duration: 3000,
//         newWindow: true,
//         close: true,
//         gravity: 'top',
//         position: 'right',
//         stopOnFocus: true
//     }).showToast()
// }

// const success = () => {
//     Toastify({
//         node: cash('#success-notification-content')
//           .clone()
//           .removeClass('hidden')[0],
//         duration: 3000,
//         newWindow: true,
//         close: true,
//         gravity: 'top',
//         position: 'right',
//         stopOnFocus: true
//       }).showToast()
// }
// const notify = (proceed) => {
//     validate.value.$touch()
//     if (validate.value.$invalid) {
//         fail()
//     } else {
//         proceed()
//     }
// }
const useValidate = (rules,formData,error) => {
    validateMethod(rules,formData,error)
    return {validate}
}

export default useValidate