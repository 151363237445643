import {ref} from 'vue'
import axios from 'axios'
import store from '@/store'

const errors = ref(null)


const login = (credentials) => {
    return new Promise((resolve,reject) => {
        axios.post(process.env.VUE_APP_BASE_URL+"/api/users/login",credentials)
        .then((res) => {
            store.dispatch('auth/attempt',res.data).then(() => {
                errors.value=null
                resolve()
            })
        }).catch((err) => {
            errors.value = err.response.data
            reject(errors.value)
        })
    })
}


const useLogin = () => {
    return {errors,login}
}

export default useLogin