// import store from '@/store'

const getAuth = () => {
    return {
        // headers: {
        //     'Authorization': 'Bearer '+store.getters['auth/token']
        // }
        headers: {
            'Authorization': 'Bearer 9bc7824d8acd2590570224798063c1b1'
        }

    }
}

export default getAuth;